import {
  Box,
  Button,
  TextInput,
  Modal,
  Center,
  Loader,
  Text,
} from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useHook from '../../../hooks/useHook';

import { mutate } from 'swr';

import MemberItem from '../MemberItem';

import { FixedSizeList as List } from 'react-window';

export const CreateMembersGroupModal = ({ opened, onClose }) => {
  const [users, setUsers] = useState([]);

  const [groupName, setGroupName] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [inputError, setInputError] = useState(false);

  const [groupMembers, setGroupMembers] = useState([]);

  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const { data: userSearch, isLoading: isLoadingSearch } = useHook(
    `/v2/chat/clients?name=${search}`
  );

  const { data } = useHook(`/v2/chat/clients/`);

  const LoaderContainer = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
      }}
    >
      <Loader variant='bars' />
    </div>
  );

  const cleanModal = () => {
    onClose();
    setGroupName('');
    setGroupMembers([]);
    setSearch('');
    setInputError(false);
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(groupName);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [groupName]);

  useEffect(() => {
    if (opened) {
      setUsers(data || []);
    }
  }, [data, opened]);

  useEffect(() => {
    if (userSearch) {
      setSearchResults(userSearch);
    }
  }, [userSearch]);

  const handleInputChange = (event) => {
    const value = event.target.value.trim();
    setGroupName(value);
    setInputError(!value);
  };

  const handleCreateGroup = async () => {
    if (!groupName) {
      setInputError(true);
      return;
    }

    const groupData = {
      title: groupName,
      clients: groupMembers,
    };

    try {
      await axios.post(
        'https://api.rebirth.port4.de/v2/chat/groups/',
        groupData,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': localStorage.getItem('auth'),
          },
        }
      );
      setGroupName('');
      setGroupMembers([]);
      mutate(`/v2/chat/groups/`);
      cleanModal();
    } catch (error) {
      console.error('Failed to create the group: ', error);
    }
  };

  const handleCheckboxChange = (id) => (event) => {
    if (event.target.checked) {
      setGroupMembers((prevGroupMembers) => [...prevGroupMembers, id]);
    } else {
      setGroupMembers((prevGroupMembers) =>
        prevGroupMembers.filter((existingId) => existingId !== id)
      );
    }
  };

  const Row = ({ index, style }) => (
    <div style={style}>
      <MemberItem
        user={users[index]}
        selectedUsers={groupMembers}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  );

  const displayUsers = search ? searchResults : users;

  return (
    <Modal
      title='Gruppe erstellen'
      opened={opened}
      onClose={() => cleanModal()}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
      >
        <TextInput
          error={inputError ? 'Gruppenname ist erforderlich' : null}
          placeholder='Gruppenname'
          label='Gruppenname'
          withAsterisk
          value={groupName}
          onChange={handleInputChange}
        />
        <TextInput
          sx={{ border: '0!important' }}
          className='search'
          icon={<IconSearch size={14} />}
          value={search}
          rightSection={
            search && (
              <Box
                sx={{ cursor: 'pointer', position: 'relative', top: 1 }}
                onClick={() => setSearch('')}
              >
                <IconX size={12} />
              </Box>
            )
          }
          onChange={(event) => setSearch(event.target.value)}
        />

        {isLoadingSearch ? (
          <LoaderContainer />
        ) : search ? (
          displayUsers.length > 0 ? (
            displayUsers.map((user, index) => (
              <MemberItem
                key={index}
                user={user}
                selectedUsers={groupMembers}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))
          ) : (
            <Center style={{ height: 250 }}>
              <Text>Keine Ergebnisse gefunden</Text>
            </Center>
          )
        ) : (
          <List
            height={400}
            itemCount={displayUsers.length}
            itemSize={50}
            width='100%'
          >
            {Row}
          </List>
        )}

        <Button
          sx={{
            border: '2px solid #A0B8CF',
            background: 'white',
            color: 'black',
            borderRadius: 32,
            '&:hover': {
              background: '#A0B8CF',
            },
          }}
          onClick={handleCreateGroup}
        >
          Erstellen
        </Button>
      </Box>
    </Modal>
  );
};
