import { Avatar, Box, Text, Checkbox } from "@mantine/core";

export default function MemberItem({
  user,
  selectedUsers,
  handleCheckboxChange,
}) {
  const isChecked = selectedUsers.includes(user.id);

  const handleBoxClick = (e) => {
    if (e.target.type !== "checkbox") {
      handleCheckboxChange(user.id)({ target: { checked: !isChecked } });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Box
        onClick={handleBoxClick}
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 10,
          padding: "8px 10px",
          cursor: "pointer",
          width: "100%",
          borderRadius: 6,
          background: isChecked ? "#F4F7F9" : "none",
          gap: 20,
        }}
      >
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange(user.id)}
          color="gray"
        />
        <Avatar
          src={user.image}
          size="sm"
          radius="sm"
          variant="outline"
          alt={user.name}
        />
        <Text
          ml={15}
          size="sm"
          sx={{
            position: "relative",
            top: 1,
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // whiteSpace: 'nowrap',
            }}
          >
            <Box
              sx={{
                display: "flex",
                whiteSpace: "nowrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text>{user.name}</Text>
              <Text
                size="xs"
                ml={10}
                sx={{
                  color: "#637385",
                  position: "relative",
                  alignContent: "center",
                  display: "flex",
                  width: "100%",
                  justifyContent: "end",
                }}
              >
                {user.id ? `ID: ${user.id}` : ""}
              </Text>
            </Box>

            <Text sx={{ fontSize: 10 }} color="gray">
              {user.program ? user.program : ""}
            </Text>
          </Box>
          {user.unreadMessages ? (
            <Box
              sx={{
                background: "red",
                borderRadius: 9999,
                color: "white",
                width: 12,
                height: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 8,
              }}
            >
              {user.unreadMessagesAmount === 0 ? 1 : user.unreadMessagesAmount}
            </Box>
          ) : (
            ""
          )}
        </Text>
      </Box>
    </Box>
  );
}
