import React from 'react';
import { Box, Text, ScrollArea, ActionIcon } from '@mantine/core';
import { IconUsers, IconTrash } from '@tabler/icons-react';

const GroupsList = ({ groups, selected, setSelected, openModalGroups }) => {
  return (
    <ScrollArea offsetScrollbars type='always'>
      {groups && groups.map((item) => (
        <Box
          key={item.id}
          onClick={() => setSelected(item)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
            padding: '8px 10px',
            cursor: 'pointer',
            borderRadius: 6,
            background:
              selected && selected.id === item.id ? '#F4F7F9' : 'none',
          }}
        >
          <Text
            size='sm'
            sx={{
              position: 'relative',
              top: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconUsers size={18} color='#98afc6' />
            <Box ml={8}>{item.title}</Box>
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text
              mr={15}
              size='xs'
              sx={{
                position: 'relative',
                top: 1,
                fontSize: 10,
                background: '#F4F7F9',
                borderRadius: 99,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                width: 15,
                padding: 2,
              }}
            >
              {item.members.length}
            </Text>
            <ActionIcon
              color='red'
              size='xs'
              onClick={() => openModalGroups(item)}
            >
              <IconTrash />
            </ActionIcon>
          </Box>
        </Box>
      ))}
    </ScrollArea>
  );
};

export default GroupsList;
