import { fetcher } from './axios';
import useSWR from 'swr';

export default function useHook(url, interval) {
  const { data, error } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: interval,
  });

  return {
    data,
    isError: error,
    isLoading: !error && !data,
  };
}
