import { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Textarea,
  ScrollArea,
  Image,
  ActionIcon,
  FileButton,
  Loader,
  Button,
  Modal,
} from '@mantine/core';
import {
  IconPdf,
  IconSend,
  IconChecks,
  IconUpload,
  IconTrash,
  IconRefresh,
} from '@tabler/icons-react';
import right from '../assets/rightmsg.png';
import left from '../assets/leftmsg.png';
import { useRef } from 'react';
import moment from 'moment-with-locales-es6';
import { Instance } from '../hooks/axios';
import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { mutate } from 'swr';
import MemberList from './ChatMembersList';

function Chat(props) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [members, setChannelMembers] = useState('');
  const [loading, setLoading] = useState(true);
  const [direct, setDirect] = useState(true);
  const [broadcast, setLoadingBroadcast] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const viewport = useRef();
  const [file, setFile] = useState(null);

  useEffect(() => {
    setMessages([]);
    setTimeout(() => {
      viewport.current.scrollTo({
        top: viewport.current.scrollHeight,
        behavior: 'auto',
      });
    }, 1500);
  }, [props]);

  useEffect(() => {
    if (file && !members && !props.user.members) {
      const fileSize = file.size / 1024 / 1024;
      if (
        (file.type === 'application/pdf' && fileSize > 5) ||
        (file.type === 'image/jpeg' && fileSize > 1) ||
        (file.type === 'image/png' && fileSize > 1) ||
        (file.type === 'image/gif' && fileSize > 1) ||
        (file.type === 'image/jpg' && fileSize > 1)
      ) {
        showNotification({
          autoClose: 3000,
          title: 'Fehler',
          message: 'Bitte Datei verkleinern',
          color: 'red',
        });
        setFile(null);
        return;
      }
      const send = async () => {
        await props.pubnub
          .sendFile({
            channel: [props.user.channel],
            file: {
              name: file.name,
              data: file,
              mimeType: file.type || 'application/json',
            },
          })
          .catch((error) => {
            console.log(error);
          });

        Instance.post(`/v2/chat/clients/push`, { id: props.user.id })
          .then(function (response) {
            if (response) {
            }
          })
          .catch(function () {});

        await Instance.post(`/v2/chat/clients/messages`, {
          channel: props.user.channel,
          unreadMessages: false,
          updateTime: new Date(),
        })
          .then(function (response) {
            if (response) {
            }
          })
          .catch(function () {});
      };

      send();
      setFile(null);
    } else if (file && members) {
      const fileSize = file.size / 1024 / 1024;
      if (
        (file.type === 'application/pdf' && fileSize > 5) ||
        (file.type === 'image/jpeg' && fileSize > 1) ||
        (file.type === 'image/png' && fileSize > 1) ||
        (file.type === 'image/gif' && fileSize > 1) ||
        (file.type === 'image/jpg' && fileSize > 1)
      ) {
        showNotification({
          autoClose: 3000,
          title: 'Fehler',
          message: 'Bitte Datei verkleinern',
          color: 'red',
        });
        setFile(null);
        return;
      }
      const send = async (channel) => {
        await props.pubnub
          .sendFile({
            channel: [channel],
            file: {
              name: file.name,
              data: file,
              mimeType: file.type || 'application/json',
            },
          })
          .catch((error) => {
            console.log(error);
          });
      };
      members &&
        members.map((member) => {
          send(member.channel);
        });
      setFile(null);
    } else if (file && props.user && props.user.members) {
      const fileSize = file.size / 1024 / 1024;
      if (
        (file.type === 'application/pdf' && fileSize > 5) ||
        (file.type === 'image/jpeg' && fileSize > 1) ||
        (file.type === 'image/png' && fileSize > 1) ||
        (file.type === 'image/gif' && fileSize > 1) ||
        (file.type === 'image/jpg' && fileSize > 1)
      ) {
        showNotification({
          autoClose: 3000,
          title: 'Fehler',
          message: 'Bitte Datei verkleinern',
          color: 'red',
        });
        setFile(null);
        return;
      }
      const send = async (channel, index) => {
        await props.pubnub
          .sendFile({
            channel: [channel],
            file: {
              name: file.name,
              data: file,
              mimeType: file.type || 'application/json',
            },
          })
          .then((response) => {
            if (index === 0) {
              const result = props.pubnub.getFileUrl({
                id: response.id,
                name: response.name,
                channel: [props.user.members[0].channel],
              });
              Instance.post(`/v2/chat/groups/sendMessage`, {
                group: props.user.id,
                message: result,
              })
                .then(function (response) {
                  if (response) {
                    getGroupMessages();
                  }
                })
                .catch(function () {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
      props.user.members &&
        props.user.members.map((member, index) => {
          send(member.channel, index);
          Instance.post(`/v2/chat/clients/push`, { id: member.id })
            .then(function (response) {
              if (response) {
              }
            })
            .catch(function () {});
        });
      setFile(null);
    }
  }, [file, props.user]);

  const publishMessage = async () => {
    // With the right payload, you can publish a message, add a reaction to a message,
    // send a push notification, or send a small payload called a signal.
    if (!message) return;

    const publishPayload = {
      channel: [props.user.channel],
      message: {
        message,
      },
      uuid: 'Coach',
    };
    await props.pubnub.publish(publishPayload);

    Instance.post(`/v2/chat/clients/push`, { id: props.user.id })
      .then(function (response) {
        if (response) {
        }
      })
      .catch(function () {});

    await Instance.post(`/v2/chat/clients/messages`, {
      channel: props.user.channel,
      unreadMessages: false,
      updateTime: new Date(),
    })
      .then(function (response) {
        if (response) {
        }
      })
      .catch(function () {});
    setMessage('');
  };

  const handleKeyDown = (event) => {
    if (!message || !message.trim()) return;
    if (event.key === 'Enter' && !event.shiftKey) {
      if (direct === 'user') {
        publishMessage();
      } else if (direct === 'group') {
        sendGroup();
      } else {
        sendBroadcast();
      }
    }
  };

  useEffect(() => {
    if (props.user) {
      if (props.user.channel) {
        setDirect('user');
      } else if (props.user.members) {
        setDirect('group');
      } else {
        setDirect('broadcast');
      }
    }
  }, [props.user]);

  const getBroadcastMessages = () => {
    setMessages([]);
    const showMessage = (msg) => {
      setMessages((messages) => [...messages, { msg }]);
    };
    Instance.get(
      `https://api.rebirth.port4.de/v2/chat/channels/${props.user.id}/messages`
    ).then((response) => {
      if (response) {
        response.data.map((item) => {
          showMessage({
            message: item.message,
            uuid: 'Coach',
            timetoken: item.timestamp,
          });
        });
      }
    });
    Instance.get(
      `https://api.rebirth.port4.de/v2/chat/channels/${props.user.id}/members`
    ).then((response) => {
      if (response) {
        setChannelMembers(response.data);
      }
    });
  };

  const getGroupMessages = () => {
    setMessages([]);
    const showMessage = (msg) => {
      setMessages((messages) => [...messages, { msg }]);
    };
    Instance.get(
      `https://api.rebirth.port4.de/v2/chat/groups/${props.user.id}/messages`
    ).then((response) => {
      if (response) {
        response.data.map((item) => {
          showMessage({
            message: item.message,
            uuid: 'Coach',
            timetoken: item.timestamp,
          });
        });
      }
    });
  };

  useEffect(() => {
    setChannelMembers();
    if (
      props.pubnub &&
      props.user &&
      !props.user.channel &&
      !props.user.members
    ) {
      getBroadcastMessages();
    }

    if (
      props.pubnub &&
      props.user &&
      props.user.members &&
      !props.user.channel
    ) {
      getGroupMessages();
    }
    if (props.pubnub && props.user && props.user.channel) {
      setLoading(true);
      setMessages([]);
      console.log(messages);
      const showMessage = (msg) => {
        setMessages((messages) => [...messages, { msg }]);
      };

      const fetch = async () => {
        const result = await props.pubnub
          .fetchMessages({
            channels: [props.user.channel],
          })
          .catch(() => {
            setLoading(false);
          });
        result.channels[props.user.channel] &&
          result.channels[props.user.channel].map((item) => {
            if (item.message.message && !item.message.file) {
              showMessage({
                message: item.message.message,
                uuid: item.uuid,
                timetoken: item.timetoken,
              });
            } else {
              if (item.message.file) {
                showMessage({
                  message: item.message,
                  uuid: item.uuid,
                  timetoken: item.timetoken,
                });
              }
            }
          });
        setLoading(false);
      };
      setTimeout(() => {
        fetch();
      }, 250);

      // add listener
      var listener = {
        status: (statusEvent) => {
          if (statusEvent.category === 'PNConnectedCategory') {
            console.log('Connected');
          }
        },
        message: (messageEvent) => {
          if (
            messageEvent.message.message &&
            messageEvent.channel === props.user.channel
          ) {
            showMessage({
              message: messageEvent.message.message,
              uuid: messageEvent.publisher,
              timetoken: messageEvent.timetoken,
            });
            setTimeout(() => {
              viewport.current.scrollTo({
                top: viewport.current.scrollHeight,
                behavior: 'smooth',
              });
              Instance.post(`/v2/chat/clients/messages`, {
                channel: props.user.channel,
                unreadMessages: false,
                updateTime: new Date(),
              })
                .then(function (response) {
                  if (response) {
                    mutate('/v2/chat/clients/');
                  }
                })
                .catch(function () {});
            }, 300);
          }
        },
        file: (event) => {
          showMessage({
            message: { file: event.file },
            uuid: event.publisher,
            timetoken: event.timetoken,
          });
          setTimeout(() => {
            viewport.current.scrollTo({
              top: viewport.current.scrollHeight,
              behavior: 'smooth',
            });
            Instance.post(`/v2/chat/clients/messages`, {
              channel: props.user.channel,
              unreadMessages: false,
              updateTime: new Date(),
            })
              .then(function (response) {
                if (response) {
                  mutate('/v2/chat/clients/');
                }
              })
              .catch(function () {});
          }, 300);
        },
        presence: (presenceEvent) => {
          //console.log(presenceEvent);
        },
      };
      props.pubnub.addListener(listener);
      // cleanup listener
      return () => {
        props.pubnub.removeListener(listener);
      };
    }
  }, [props.user]);

  const deleteMessage = async (timetoken) => {
    await props.pubnub.deleteMessages(
      {
        channel: props.user.channel,
        start: parseInt(timetoken) + 2,
        end: parseInt(timetoken) - 2,
      },
      function (status, response) {
        setMessages([]);
        const showMessage = (msg) => {
          setMessages((messages) => [...messages, { msg }]);
        };
        const fetch = async () => {
          const result = await props.pubnub
            .fetchMessages({
              channels: [props.user.channel],
            })
            .catch(() => {
              setLoading(false);
            });
          result.channels[props.user.channel] &&
            result.channels[props.user.channel].map((item) => {
              console.log(item);
              if (item.message.message && !item.message.file) {
                showMessage({
                  message: item.message.message,
                  uuid: item.uuid,
                  timetoken: item.timetoken,
                });
              } else {
                if (item.message.file) {
                  showMessage({
                    message: item.message,
                    uuid: item.uuid,
                    timetoken: item.timetoken,
                  });
                }
              }
            });
          setLoading(false);
        };
        setTimeout(() => {
          fetch();
          setTimeout(() => {
            viewport.current.scrollTo({
              top: viewport.current.scrollHeight,
              behavior: 'auto',
            });
          }, 500);
        }, 250);
      }
    );
  };

  const openModal = (timetoken) =>
    modals.openConfirmModal({
      title: 'Nachricht löschen',
      centered: true,

      children: (
        <Text size='sm'>
          {`Bist du dir sicher, dass du die Nachricht unwiderruflich löschen
        möchtest?`}
        </Text>
      ),
      labels: { confirm: 'Löschen', cancel: 'Abbrechen' },
      confirmProps: { color: 'red' },

      onCancel: () => console.log('Abbrechen'),
      onConfirm: () => deleteMessage(timetoken),
    });

  const getImage = (message) => {
    const result = props.pubnub.getFileUrl({
      channel: [props.user.channel],
      id: message.msg.message.file.id,
      name: message.msg.message.file.name,
    });

    return result;
  };
  useEffect(() => {
    if (props.pubnub && props.user && props.user.channel) {
      // subscribe to a channel
      props.pubnub.subscribe({
        channels: [props.user.channel],
      });
      // cleanup subscription
      return () => {
        props.pubnub.unsubscribe({
          channels: [props.user.channel],
        });
      };
    }
  }, [props]);

  const sendBroadcast = () => {
    setMessage('');
    setLoadingBroadcast(true);
    Instance.post(`/v2/chat/channels/sendMessage`, {
      channel: props.user.id,
      message,
    })
      .then(function (response) {
        if (response) {
          getBroadcastMessages();
          setLoadingBroadcast(false);
        }
      })
      .catch(function () {});

    if (members && members.length) {
      members.map((item) => {
        Instance.post(`/v2/chat/clients/push`, { id: item.id })
          .then(function (response) {
            if (response) {
            }
          })
          .catch(function () {});
      });
    }
  };

  const sendGroup = () => {
    setMessage('');
    Instance.post(`/v2/chat/groups/sendMessage`, {
      group: props.user.id,
      message,
    })
      .then(function (response) {
        if (response) {
          getGroupMessages();
        }
      })
      .catch(function () {});
    if (props.user.members && props.user.members.length) {
      props.user.members.map((item) => {
        Instance.post(`/v2/chat/clients/push`, { id: item.id })
          .then(function (response) {
            if (response) {
            }
          })
          .catch(function () {});
      });
    }
  };

  const updateBroadcastMembers = async (channelId, onCompleted) => {
    try {
      const response = await Instance.post(
        'https://api.rebirth.port4.de/v2/chat/channels/updateMembers',
        {
          channelId: channelId,
        }
      );

      if (onCompleted) {
        onCompleted();
      }

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshBroadcastMembers = () => {
    Instance.get(
      `https://api.rebirth.port4.de/v2/chat/channels/${props.user.id}/members`
    ).then((response) => {
      if (response) {
        setChannelMembers(response.data);
        mutate('/v2/chat/channels/');
      }
    });
  };

  const handleUpdateClick = () => {
    if (props.broadcastId) {
      setIsUpdating(true);
      updateBroadcastMembers(props.broadcastId, () => {
        refreshBroadcastMembers();
        setIsUpdating(false);
      });
    } else {
      console.error('broadcastId undefined');
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title={`Teilnehmer${members ? ` (${members.length})` : ''}`}
      >
        {members && members.length >= 0 ? (
          <Button
            variant='default'
            leftIcon={
              isUpdating ? (
                <Loader size='xs' />
              ) : (
                <IconRefresh size={16} color='#7F7F7F' />
              )
            }
            sx={{
              background: '#F8FAFC',
              color: '#7F7F7F',
              borderRadius: 100,
              marginBottom: 20,
            }}
            onClick={handleUpdateClick}
            disabled={isUpdating}
          >
            Aktualisieren
          </Button>
        ) : (
          ''
        )}
        <MemberList members={members} user={props.user} />
      </Modal>{' '}
      <Box
        sx={{
          background: '#F4F7F9',
          borderRadius: 15,
          minHeight: 200,
          height: '100%',
          '@media (min-width: 768px)': {
            height: 'calc(100vh - 30px)',
          },
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: '#c9d7e4',
            zIndex: 100,
            borderRadius: 15,
            height: 36,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            justifyContent: 'space-between',
            display: 'flex',
            padding: '10px 20px ',
            alignItems: 'center',
            width: 'calc(100% - 40px)',
            boxShadow: '0 5px 20px -10px rgba(0,0,0,0.1)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {props.user && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text>
                  {props.user?.name || props.user?.title}
                  {/* {props.user?.program && ` #${props.user?.id}`} */}
                </Text>
                <Text
                  size='xs'
                  ml={10}
                  sx={{
                    color: '#637385',
                    paddingTop: 2,
                  }}
                >
                  {props.user?.program ? `ID: ${props.user?.id}` : ''}
                </Text>
              </Box>
            )}
            <Text sx={{ fontSize: 12, color: '#637385' }}>
              {props.user?.program}
            </Text>
          </Box>

          {members ? (
            <Button onClick={open} variant='default' sx={{ color: 'black ' }}>
              {members.length + ' Teilnehmer'}
            </Button>
          ) : (
            ''
          )}
          {props.user && props.user.members ? (
            <Button onClick={open} variant='default' sx={{ color: 'black ' }}>
              {props.user.members.length + ' Teilnehmer'}
            </Button>
          ) : (
            ''
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',

            '@media (min-width: 768px)': {
              padding: '20px 40px',
            },
            width: '-webkit-fill-available',
          }}
        >
          <ScrollArea
            viewportRef={viewport}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100vh - 180px)',

              '@media (min-width: 768px)': {
                height: 'calc(100vh - 150px)',
              },
            }}
          >
            {/* ... content */}
            {messages && messages.length && !loading ? (
              messages.map((message, index) => {
                return message.msg.message && message.msg.message.file ? (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',

                      '@media (min-width: 768px)': {
                        justifyContent:
                          message.msg.uuid === 'Coach' ? 'start' : 'end',
                      },
                      paddingRight: 30,
                      paddingLeft: 30,
                    }}
                  >
                    <Box
                      sx={{
                        background:
                          message.msg.uuid === 'Coach' ? 'white' : '#DDE5ED',
                        borderRadius: 8,
                        borderTopRightRadius:
                          message.msg.uuid === 'Coach' ? 8 : 0,
                        borderTopLeftRadius:
                          message.msg.uuid === 'Coach' ? 0 : 8,
                        marginBottom: 15,
                        padding: 20,
                        alignItems: 'flex-end',
                        margin: 5,
                        alignSelf: 'flex-end',
                        width: '90%',
                        '@media (min-width: 768px)': {
                          width: '30%',
                        },
                        position: 'relative',
                      }}
                    >
                      {message.msg.uuid === 'Coach' ? (
                        <Image
                          src={left}
                          sx={{
                            position: 'absolute',
                            backgroundColor: '#fff',
                            left: -16,
                            top: 4,
                            width: '16px!important',
                            height: 16,
                            zIndex: 99,
                            transform: 'rotate(180deg)',
                          }}
                        />
                      ) : (
                        <Image
                          src={right}
                          sx={{
                            position: 'absolute',
                            backgroundColor: '#fff',
                            right: -15,
                            top: 0,
                            width: '16px!important',
                            height: 16,
                            zIndex: 99,
                          }}
                        />
                      )}
                      {message.msg.message.file.name.indexOf('.pdf') != -1 ? (
                        <>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {direct === 'user' && (
                              <ActionIcon
                                onClick={() => openModal(message.msg.timetoken)}
                                sx={{ position: 'absolute', right: 0, top: 0 }}
                              >
                                <IconTrash size={10} color='red' />
                              </ActionIcon>
                            )}
                            <IconPdf />
                            <a
                              href={getImage(message)}
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                color: 'black',
                                marginLeft: 10,
                                wordBreak: 'break-all',
                              }}
                            >
                              {message.msg.message.file.name}
                            </a>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            {direct === 'user' &&
                              message.msg.uuid === 'Coach' && (
                                <ActionIcon
                                  onClick={() =>
                                    openModal(message.msg.timetoken)
                                  }
                                  sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                  }}
                                >
                                  <IconTrash size={10} color='red' />
                                </ActionIcon>
                              )}
                            <Image src={getImage(message)} />
                          </Box>
                        </>
                      )}

                      <Text
                        sx={{
                          fontSize: 12,
                          color: '#637385',
                          textAlign: 'right',
                          position: 'relative',
                          top: 10,
                        }}
                      >
                        {' '}
                        {moment(new Date(message.msg.timetoken / 1e4))
                          .locale('de')
                          .format('DoMM.YYYY, H:mm')}
                        {message.msg.uuid === 'Coach' && direct === 'user' && (
                          <Box
                            sx={{
                              position: 'relative',
                              top: 4,
                              left: 2,
                              display: 'inline',
                            }}
                          >
                            {props.user &&
                              props.user.lastSeen &&
                              props.user.lastSeen.date &&
                              moment(props.user.lastSeen.date)
                                .add(1, 'hours')
                                .isAfter(
                                  new Date(message.msg.timetoken / 1e4)
                                ) && <IconChecks size={16} color='#98afc6' />}
                          </Box>
                        )}
                      </Text>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      marginTop: index === 0 ? 50 : 0,
                      '@media (min-width: 768px)': {
                        justifyContent:
                          message.msg.uuid === 'Coach' ? 'start' : 'end',
                      },
                      paddingRight: 30,
                      paddingLeft: 30,
                    }}
                  >
                    <Box
                      sx={{
                        background:
                          message.msg.uuid === 'Coach' ? 'white' : '#DDE5ED',
                        borderRadius: 8,
                        borderTopRightRadius:
                          message.msg.uuid === 'Coach' ? 8 : 0,
                        borderTopLeftRadius:
                          message.msg.uuid === 'Coach' ? 0 : 8,
                        marginBottom: 15,
                        padding: 20,
                        wordBreak: 'break-word',
                        alignItems: 'flex-end',
                        margin: 5,
                        alignSelf: 'flex-end',
                        width: '90%',
                        '@media (min-width: 768px)': {
                          width: '30%',
                        },
                        position: 'relative',
                      }}
                    >
                      {message.msg.uuid === 'Coach' ? (
                        <Image
                          src={left}
                          sx={{
                            position: 'absolute',
                            backgroundColor: '#fff',
                            left: -16,
                            top: 4,
                            width: '16px!important',
                            height: 16,
                            zIndex: 99,
                            transform: 'rotate(180deg)',
                          }}
                        />
                      ) : (
                        <Image
                          src={right}
                          sx={{
                            position: 'absolute',
                            backgroundColor: '#fff',
                            right: -15,
                            top: 0,
                            width: '16px!important',
                            height: 16,
                            zIndex: 99,
                          }}
                        />
                      )}
                      {message.msg.uuid === 'Coach' && direct === 'user' && (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <ActionIcon
                            onClick={() => openModal(message.msg.timetoken)}
                            sx={{ position: 'absolute', right: 0, top: 0 }}
                          >
                            <IconTrash size={10} color='red' />
                          </ActionIcon>{' '}
                        </Box>
                      )}
                      {message.message &&
                      message.message.startsWith('https') &&
                      !message.msg.message.includes('jpg') &&
                      !message.msg.message.includes('png') &&
                      !message.msg.message.includes('jpeg') &&
                      !message.msg.message.includes('gif') ? (
                        <a href={message.message} target='_blank'>
                          {message.message}
                        </a>
                      ) : message.message ||
                        (message.msg.message &&
                          message.msg.message.startsWith('https') &&
                          !message.msg.message.includes('jpg') &&
                          !message.msg.message.includes('png') &&
                          !message.msg.message.includes('jpeg') &&
                          !message.msg.message.includes('gif') &&
                          !message.msg.message.includes('pdf')) ? (
                        <a href={message.msg.message} target='_blank'>
                          {message.msg.message}
                        </a>
                      ) : message.message ||
                        (message.msg.message &&
                          (message.msg.message.includes('jpg') ||
                            message.msg.message.includes('png') ||
                            message.msg.message.includes('jpeg') ||
                            message.msg.message.includes('gif'))) ? (
                        <Image src={message.msg.message} />
                      ) : (
                        message.msg.message
                      )}
                      <Text
                        sx={{
                          fontSize: 12,
                          color: '#637385',
                          textAlign: 'right',
                          position: 'relative',
                          top: 10,
                        }}
                      >
                        {' '}
                        {moment(
                          new Date(
                            direct === 'user'
                              ? message.msg.timetoken / 1e4
                              : message.msg.timetoken
                          )
                        )
                          .locale('de')
                          .format('DoMM.YYYY, H:mm')}{' '}
                        {message.msg.uuid === 'Coach' && direct === 'user' && (
                          <Box
                            sx={{
                              position: 'relative',
                              top: 4,
                              display: 'inline',
                            }}
                          >
                            {props.user &&
                              props.user.lastSeen &&
                              props.user.lastSeen.date &&
                              moment(props.user.lastSeen.date)
                                .add(1, 'hours')
                                .isAfter(
                                  new Date(message.msg.timetoken / 1e4)
                                ) && <IconChecks size={16} color='#98afc6' />}
                          </Box>
                        )}
                      </Text>
                    </Box>
                  </Box>
                );
              })
            ) : loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Loader mt={60} size='sm' />
              </Box>
            ) : props.user ? (
              'Keine Nachrichten'
            ) : (
              ''
            )}
          </ScrollArea>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: 0,
            padding: '20px 40px',
            alignItems: 'end',
            zIndex: 100,
            width: '-webkit-fill-available',
            borderTop: '1px solid #DDE5ED',
            background: '#F4F7F9',
            borderRadius: 15,
          }}
        >
          <Box sx={{ position: 'relative', top: -7, left: -10 }}>
            {true && (
              <FileButton
                onChange={setFile}
                accept='image/png,image/jpg,application/pdf,image/gif'
              >
                {(props) => (
                  <IconUpload {...props} color='#999999' strokeWidth={1.5} />
                )}
              </FileButton>
            )}
          </Box>

          <Textarea
            sx={{ width: '100%' }}
            placeholder='Nachricht'
            autosize
            onKeyPress={handleKeyDown}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            minRows={1}
          />
          <ActionIcon
            sx={{
              position: 'relative',
              top: -7,
              left: 10,
              '&:hover': {
                background: '#f0f3f7',
              },
            }}
            disabled={!message}
            onClick={() =>
              direct === 'user'
                ? publishMessage()
                : direct === 'group'
                ? sendGroup()
                : sendBroadcast()
            }
          >
            {broadcast ? (
              <Loader />
            ) : (
              <IconSend color='#999999' strokeWidth={1.5} />
            )}
          </ActionIcon>
        </Box>
      </Box>
    </>
  );
}

export default React.memo(Chat);
