import React from 'react';
import { Box, Text, ScrollArea, ActionIcon } from '@mantine/core';
import { IconUsers, IconTrash } from '@tabler/icons-react';

const ChallengeList = ({ challenges, selected, openDeleteChallengeModal }) => {
  return (
    <ScrollArea offsetScrollbars type='always'>
      {challenges?.map((challengeItem) => {
        const challenge = challengeItem.challenge;
        return (
          <Box
            key={challengeItem.challengeId}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 10,
              padding: '8px 10px',
              cursor: 'pointer',
              borderRadius: 6,
              background:
                selected && selected.challengeId === challengeItem.challengeId
                  ? '#F4F7F9'
                  : 'none',
            }}
          >
            <Text
              size='sm'
              sx={{
                position: 'relative',
                top: 1,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconUsers size={18} color='#98afc6' />
              <Box ml={8}>{challenge.title}</Box>
            </Text>
            <ActionIcon
              color='red'
              size='xs'
              onClick={() => openDeleteChallengeModal(challengeItem)}
            >
              <IconTrash />
            </ActionIcon>
          </Box>
        );
      })}
    </ScrollArea>
  );
};

export default ChallengeList;
