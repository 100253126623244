import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Modal,
  MultiSelect,
  Select,
  Textarea,
  TextInput,
  Loader,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment-with-locales-es6';
import { Instance } from '../../../hooks/axios';
import { mutate } from 'swr';

export const CreateChallengeModal = ({ opened, onClose, programms }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [programm, setProgramm] = useState('');
  const [goal, setGoal] = useState('');
  const [goalType, setGoalType] = useState('');
  const [goalValue, setGoalValue] = useState('');
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState('');
  const [type, setType] = useState('');
  const [dataSource, setDataSource] = useState('');
  const [activityType, setActivityType] = useState([]);

  const [durationHours, setDurationHours] = useState('');
  const [durationMinutes, setDurationMinutes] = useState('');

  const [loadingChallenge, setLoadingChallenge] = useState(false);

  const resetFields = () => {
    setTitle('');
    setDescription('');
    setProgramm('');
    setGoal('');
    setGoalType('');
    setGoalValue('');
    setStart(new Date());
    setEnd('');
    setType('');
    setDataSource('');
    setActivityType([]);
    setDurationHours('');
    setDurationMinutes('');
  };

  useEffect(() => {
    if (onClose) {
      resetFields();
    }
  }, [onClose]);

  const handleCreateChallenge = async () => {
    setLoadingChallenge(true);
    let goalValueToSend = Number(goalValue);

    // if the goalType is DURATION, calculate the total minutes from hours and minutes
    if (goalType === 'DURATION') {
      const totalSeconds =
        parseInt(durationHours, 10) * 3600 + parseInt(durationMinutes, 10) * 60;
      goalValueToSend = totalSeconds;
    }

    const challengeData = {
      title,
      description,
      slogan: '',
      programId: programm,
      goal,
      goal_type: goalType,
      goal_value: goalValueToSend,
      start:
        dataSource === 'workout'
          ? start
          : moment(start).format('YYYY-MM-DDT00:00'),
      end,
      type,
      repetition: 'NONE',
      enrollment_method: 'MANUAL',
      visibility: 'PUBLIC',
      calculation_method: 'SUM',
      activity_types: activityType,
    };

    try {
      await Instance.post('/v2/challenge', challengeData, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (response) {
          setLoadingChallenge(false);
          mutate('/v2/challenges/');
          resetFields();
          onClose();
        }
      });
    } catch (error) {
      resetFields();
      setLoadingChallenge(false);
    }
  };

  // update the end date whenever the goal or start changes
  useEffect(() => {
    let endDate;
    const startDate = moment(start);
    if (startDate.isValid() && goal !== 'TOTAL') {
      switch (goal) {
        case 'DAILY':
          endDate = startDate.clone().add(1, 'days').toDate();
          break;
        case 'WEEKLY':
          endDate = startDate.clone().add(1, 'weeks').toDate();
          break;
        case 'MONTHLY':
          endDate = startDate.clone().add(1, 'months').toDate();
          break;
        default:
          endDate = '';
      }
      if (endDate instanceof Date && !isNaN(endDate)) {
        setEnd(endDate);
      }
    }
  }, [goal, start]);

  return (
    <Modal
      title='Challenge erstellen'
      opened={opened}
      onClose={() => onClose()}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
      >
        <TextInput
          label='Titel'
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          withAsterisk
        />

        <Textarea
          label='Description'
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          withAsterisk
        />

        <Select
          label='Programm'
          value={programm}
          mt={10}
          onChange={setProgramm}
          data={programms}
          required
        />

        <Select
          label='Dauer der Challenge'
          value={goal}
          onChange={(value) => setGoal(value)}
          data={['DAILY', 'WEEKLY', 'MONTHLY', 'TOTAL']}
          required
        />

        <DatePickerInput
          label='Startdatum'
          value={start}
          onChange={setStart}
          required
        />

        {goal !== 'TOTAL' && (
          <DatePickerInput label='Enddatum' value={end} disabled />
        )}

        <Select
          label='Objektivtyp'
          value={goalType}
          onChange={(value) => setGoalType(value)}
          data={['POINTS', 'DISTANCE', 'CALORIES', 'DURATION', 'STEPS']}
          required
        />

        {goalType === 'STEPS' && (
          <TextInput
            type='number'
            label='Wert des Ziels'
            value={goalValue}
            onChange={(event) => setGoalValue(event.target.value)}
            withAsterisk
          />
        )}

        {goalType === 'DISTANCE' && (
          <TextInput
            type='number'
            label='Wert des Ziels (in km)'
            step='0.01'
            value={goalValue}
            onChange={(event) => setGoalValue(event.target.value)}
            withAsterisk
          />
        )}

        {goalType === 'CALORIES' && (
          <TextInput
            type='number'
            label='Wert des Ziels(in calories)'
            value={goalValue}
            onChange={(event) => setGoalValue(event.target.value)}
            withAsterisk
          />
        )}

        {goalType === 'DURATION' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TextInput
              type='number'
              label='Wert des Ziels (in hours)'
              value={durationHours}
              onChange={(event) => setDurationHours(event.target.value)}
              withAsterisk
            />
            <TextInput
              type='number'
              label='Wert des Ziels (in minutes)'
              value={durationMinutes}
              onChange={(event) => setDurationMinutes(event.target.value)}
              withAsterisk
            />
          </Box>
        )}

        <Select
          label='Typ der Challenge'
          value={type}
          onChange={(value) => setType(value)}
          data={['INDIVIDUAL', 'GROUP', 'TEAM']}
          required
        />

        <Select
          label='Datentyp'
          value={dataSource}
          onChange={(value) => setDataSource(value)}
          data={['daily_summary', 'workout']}
          required
        />
        {dataSource === 'workout' && (
          <MultiSelect
            label='Art der Aktivitäten'
            data={[
              'RUNNING',
              'CROSSFIT',
              'CYCLING',
              'SWIMMING',
              'OTHER',
              'ROWING',
              'WALKING',
              'MINDFULNESS',
              'YOGA',
              'STRENGTH_TRAINING',
            ]}
            value={activityType}
            onChange={setActivityType}
            required
          />
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 40,
          }}
        >
          <Button
            sx={{
              border: '2px solid #A0B8CF',
              background: 'white',
              color: 'black',
              borderRadius: 32,
              '&:hover': {
                background: '#A0B8CF',
              },
            }}
            onClick={() => handleCreateChallenge()}
          >
            {loadingChallenge ? <Loader size='sm' /> : 'Erstellen'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
