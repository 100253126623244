import React from 'react';
import { Box, Text, ScrollArea, ActionIcon } from '@mantine/core';
import { IconBroadcast, IconTrash } from '@tabler/icons-react';
import PropTypes from 'prop-types';

const BroadcastList = ({
  broadcasts,
  selected,
  setSelected,
  openModal,
  onBroadcastSelect,
}) => {
  const handleBroadcastClick = (item) => {
    setSelected(item);
    if (onBroadcastSelect) {
      onBroadcastSelect(item.id);
    }
  };

  return (
    <ScrollArea offsetScrollbars type='always'>
      {broadcasts?.map((item) => (
        <Box
          key={item.id}
          onClick={() => handleBroadcastClick(item)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
            padding: '8px 10px',
            cursor: 'pointer',
            borderRadius: 6,
            background:
              selected && selected.id === item.id ? '#F4F7F9' : 'none',
          }}
        >
          <Text
            size='sm'
            sx={{
              position: 'relative',
              top: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconBroadcast color='#98afc6' />
            <Box ml={8}>{item.title}</Box>
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text
              mr={15}
              size='xs'
              sx={{
                position: 'relative',
                top: 1,
                fontSize: 10,
                background: '#F4F7F9',
                borderRadius: 99,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                width: 15,
                padding: 2,
              }}
            >
              {item.memberCount}
            </Text>
            <ActionIcon color='red' size='xs' onClick={() => openModal(item)}>
              <IconTrash />
            </ActionIcon>
          </Box>
        </Box>
      ))}
    </ScrollArea>
  );
};

BroadcastList.propTypes = {
  broadcasts: PropTypes.array,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  openModal: PropTypes.func,
};

export default BroadcastList;
