import React from 'react';
import { Accordion } from '@mantine/core';

const statisticsAccordion = ({
  stepsStats,
  activitiesStats,
  bpmStats,
  mentalActivityStats,
  user,
  renderUserChartCard,
  openChart,
}) => (
  <Accordion defaultValue='Schritte'>
    <Accordion.Item value='Schritte'>
      <Accordion.Control>Schritte</Accordion.Control>
      <Accordion.Panel>
        {renderUserChartCard(
          stepsStats.dataWeekly,
          stepsStats.dataMonthly,
          '#12B886',
          'Schritte',
          stepsStats.currentWeekData,
          stepsStats.currentMonthData,
          stepsStats.currentYear,
          stepsStats.dataMonthly,
          stepsStats.dataWeekly,
          false,
          stepsStats.increaseDateHandle,
          stepsStats.decreaseDateHandle,
          stepsStats.increaseYear,
          stepsStats.decreaseYear,
          'IconWalk',
          '#8884d8'
        )}
      </Accordion.Panel>
    </Accordion.Item>

    <Accordion.Item value='Aktivitat'>
      <Accordion.Control onClick={() => openChart('activities')}>
        Aktivitat
      </Accordion.Control>
      <Accordion.Panel>
        {renderUserChartCard(
          activitiesStats.dataWeekly,
          activitiesStats.dataMonthly,
          '#228BE6',
          'Aktivitat',
          activitiesStats.currentWeekData,
          activitiesStats.currentMonthData,
          activitiesStats.currentYear,
          activitiesStats.dataMonthly,
          activitiesStats.dataWeekly,
          false,
          activitiesStats.increaseDateHandle,
          activitiesStats.decreaseDateHandle,
          activitiesStats.increaseYear,
          activitiesStats.decreaseYear,
          'IconRun',
          '#8884d8'
        )}
      </Accordion.Panel>
    </Accordion.Item>

    <Accordion.Item value='BPM'>
      <Accordion.Control onClick={() => openChart('bpm')}>
        BPM
      </Accordion.Control>
      <Accordion.Panel>
        {user.clientData.connectedDevice &&
          user.clientData.connectedDevice !== 'FitBit' &&
          user.clientData.connectedDevice !== 'Oura' &&
          user.clientData.connectedDevice !== 'Polar' &&
          renderUserChartCard(
            bpmStats.dataWeekly,
            bpmStats.dataMonthly,
            '#FA5252',
            'BPM',
            bpmStats.currentWeekData,
            bpmStats.currentMonthData,
            bpmStats.currentYear,
            bpmStats.dataMonthly,
            bpmStats.dataWeekly,
            true,
            bpmStats.increaseDateHandle,
            bpmStats.decreaseDateHandle,
            bpmStats.increaseYear,
            bpmStats.decreaseYear,
            'IconHeart',
            '#8884d8'
          )}
      </Accordion.Panel>
    </Accordion.Item>
    <Accordion.Item value='Mental Aktivität'>
      <Accordion.Control onClick={() => openChart('mental')}>
        Mental Aktivität
      </Accordion.Control>
      <Accordion.Panel>
        {renderUserChartCard(
          mentalActivityStats.dataWeekly,
          mentalActivityStats.dataMonthly,
          '#FAB005',
          'Mental Aktivität',
          mentalActivityStats.currentWeekData,
          mentalActivityStats.currentMonthData,
          mentalActivityStats.currentYear,
          mentalActivityStats.dataMonthly,
          mentalActivityStats.dataWeekly,
          false,
          mentalActivityStats.increaseDateHandle,
          mentalActivityStats.decreaseDateHandle,
          mentalActivityStats.increaseYear,
          mentalActivityStats.decreaseYear,
          'IconBrain',
          '#8884d8'
        )}
      </Accordion.Panel>
    </Accordion.Item>
  </Accordion>
);

export default statisticsAccordion;
