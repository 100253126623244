// ChartToggleButtons.js
import React from 'react';
import { Box, Badge } from '@mantine/core';

const ChartToggleButtons = ({ toggleChart, activeCharts, user }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: 20,
        gap: 20,
      }}
    >
      <Badge
        size='lg'
        color='teal'
        radius='xl'
        onClick={() => toggleChart('steps')}
        sx={{
          cursor: 'pointer',
          ...(activeCharts.steps ? { border: '2px solid #12B886' } : {}),
          ...(activeCharts.steps &&
          Object.values(activeCharts).filter(Boolean).length === 1
            ? { cursor: 'default', pointerEvents: 'none' }
            : {}),
        }}
      >
        Schritte
      </Badge>
      <Badge
        size='lg'
        color='blue'
        radius='xl'
        onClick={() => toggleChart('activities')}
        sx={{
          cursor: 'pointer',
          ...(activeCharts.activities ? { border: '2px solid #228BE6' } : {}),
          ...(activeCharts.activities &&
          Object.values(activeCharts).filter(Boolean).length === 1
            ? { cursor: 'default', pointerEvents: 'none' }
            : {}),
        }}
      >
        Aktivität
      </Badge>
      {user.clientData.connectedDevice &&
        user.clientData.connectedDevice !== 'FitBit' &&
        user.clientData.connectedDevice !== 'Oura' &&
        user.clientData.connectedDevice !== 'Polar' && (
          <Badge
            size='lg'
            radius='xl'
            color='red'
            onClick={() => toggleChart('bpm')}
            sx={{
              cursor: 'pointer',
              ...(activeCharts.bpm ? { border: '2px solid #FA5252' } : {}),
              ...(activeCharts.bpm &&
              Object.values(activeCharts).filter(Boolean).length === 1
                ? { cursor: 'default', pointerEvents: 'none' }
                : {}),
            }}
          >
            BPM
          </Badge>
        )}
      <Badge
        size='lg'
        radius='xl'
        color='yellow'
        onClick={() => toggleChart('mental')}
        sx={{
          cursor: 'pointer',
          ...(activeCharts.mental ? { border: '2px solid #FAB005' } : {}),
          ...(activeCharts.mental &&
          Object.values(activeCharts).filter(Boolean).length === 1
            ? { cursor: 'default', pointerEvents: 'none' }
            : {}),
        }}
      >
        Mental Aktivität
      </Badge>
    </Box>
  );
};

export default ChartToggleButtons;
