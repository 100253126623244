import React from 'react';
import { Box, Text, Avatar, ScrollArea } from '@mantine/core';

const MemberList = ({ members, user }) => {
  return (
    <ScrollArea
      sx={{
        backgroundColor: '#F8FAFC',
      }}
      offsetScrollbars
      h={400}
      type='always'
    >
      {members &&
        members?.map((user, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 10,
              padding: '8px 10px',
              cursor: 'pointer',
              borderRadius: 6,
            }}
          >
            <Avatar
              src={user.image}
              size='sm'
              radius='sm'
              variant='outline'
              alt={user.name}
            />
            <Box
              ml={15}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  top: 1,
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text size='sm'>{user.name}</Text>
                <Text size='xs' mr={15} sx={{ color: '#637385' }}>
                  {user.id ? `ID: ${user.id}` : ''}
                </Text>
              </Box>
              <Text size='xs' sx={{ color: '#637385' }}>
                {user.program ? user.program : ''}
              </Text>
            </Box>
          </Box>
        ))}
      {user &&
        user.members &&
        user.members.map((user, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 10,
                padding: '8px 10px',
                cursor: 'pointer',
                borderRadius: 6,
              }}
            >
              <Avatar
                src={
                  user.image
                    ? `https://api.rebirth.port4.de/uploads/images/${user.image}`
                    : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                }
                size='sm'
                radius='sm'
                variant='outline'
                alt={user.name}
              />
              <Box
                ml={15}
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    top: 1,
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text
                    size='sm'
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    {user.name}
                  </Text>
                  <Text
                    size='xs'
                    mr={15}
                    sx={{
                      color: '#637385',
                      position: 'relative',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'end',
                    }}
                  >
                    {user.id ? `ID: ${user.id}` : ''}
                  </Text>
                </Box>
                <Text
                  size='xs'
                  sx={{
                    color: '#637385',
                    position: 'relative',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  {user.program ? user.program : ''}
                </Text>
              </Box>
            </Box>
          );
        })}
    </ScrollArea>
  );
};

export default MemberList;
