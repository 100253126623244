import axios from 'axios';

export const Instance = axios.create({
  baseURL: 'https://api.rebirth.port4.de',
  headers: {
    'Content-Type': 'application/json',
    'X-AUTH-TOKEN': localStorage.getItem('auth'),
  },
});

export const fetcher = (url) => {
  return Instance.get(url, {
    headers: {
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': localStorage.getItem('auth'),
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};
