import React, { useState, useEffect, useCallback } from 'react';
import { Box, Text } from '@mantine/core';
import axios from 'axios';
import { ReactComponent as BroccoliIcon } from './../assets/icons/icon-nutrition-broccoli.svg';
import { ReactComponent as WaterglassIcon } from './../assets/icons/icon-nutrition-waterglass.svg';
import { ReactComponent as AppleIcon } from './../assets/icons/icon-nutrition-apple.svg';

const nutritionTypes = [
  {
    type: 'vegetables',
    title: 'Gemüse',
    description: 'Empfohlen: Mind. 3 Portionen',
    color: '#178F70',
    amount: [1, 2, 3],
    IconComponent: BroccoliIcon,
  },
  {
    type: 'fruit',
    title: 'Obst',
    description: 'Empfohlen: Mind. 2 Portionen',
    color: '#EF4444',
    amount: [1, 2],
    IconComponent: AppleIcon,
  },
  {
    type: 'water',
    title: 'Wasser',
    description: 'Empfohlen: Mind. 8 Gläser (1,6l)',
    color: '#34628E',
    amount: [1, 2, 3, 4, 5, 6, 7, 8],
    IconComponent: WaterglassIcon,
  },
];

export const NutritionCard = ({ openUser }) => {
  const [nutritionData, setNutritionData] = useState(null);

  const fetchNutritionData = useCallback(() => {
    if (openUser) {
      axios
        .get(`https://api.rebirth.port4.de/v2/nutritions`, {
          headers: {
            'X-AUTH-TOKEN': openUser.authCode,
          },
        })
        .then((response) => {
          setNutritionData(response.data);
        })
        .catch((error) => {
          console.error('There was an error!', error);
        });
    }
  }, [openUser]);

  useEffect(() => {
    fetchNutritionData();
  }, [fetchNutritionData]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {nutritionTypes.map((n) => {
        const IconComponent = n.IconComponent;
        const nutritionItem = nutritionData?.find((x) => x.type === n.type);
        const nutritionAmount = nutritionItem?.amount || 0;

        console.log(n.type, nutritionAmount);

        return (
          <Box
            style={{
              padding: 10,
              marginBottom: n.type !== 'water' ? 20 : 10,
            }}
          >
            <Text
              sx={{
                fontSize: 20,
                fontWeight: '500',
                fontFamily: 'Graphit',
              }}
            >
              {n.title}
            </Text>
            <Text
              sx={{
                color: '#A5ACB6',
                marginTop: 5,
              }}
            >
              {n.description}
            </Text>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 20,
              }}
            >
              {n.amount.map((item, index) => (
                <Box
                  key={n.type + index}
                  sx={{
                    marginRight: n.type === 'water' ? 15 : 10,
                    width: 40,
                    height: 35,
                  }}
                >
                  <IconComponent
                    style={{
                      fill: nutritionAmount >= index + 1 ? n.color : '#E0E0DF',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
