import React, { useState, useEffect } from 'react';
import {
  Card,
  Text,
  Box,
  ActionIcon,
  SegmentedControl,
  Button,
} from '@mantine/core';
import {
  IconChevronLeft,
  IconChevronRight,
  IconRun,
  IconWalk,
  IconHeart,
  IconBrain,
} from '@tabler/icons-react';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Line,
} from 'recharts';

const UserChartCard = ({
  title,
  onCloseChart,
  canCloseChart,
  currentdate,
  icon,
  increaseDateHandle,
  decreaseDateHandle,
  currentWeek,
  currentMonth,
  currentYear,
  dataWeekly,
  dataMonthly,
  frontColor,
  bpm,
  totalWeek,
  totalMonth,
  totalMonthAverageBPM,
  totalWeekAverageBPM,
  increaseYear,
  decreaseYear,
}) => {
  const [weeklyData, setWeeklyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [highestWeekly, setHighestWeekly] = useState();
  const [highestMonthly, setHighestMonthly] = useState();
  const [select, setSelect] = useState('week');

  const getIconComponent = (iconName) => {
    switch (iconName) {
      case 'IconRun':
        return <IconRun size={18} color='#228BE6' />;
      case 'IconWalk':
        return <IconWalk size={18} color='#12B886' />;
      case 'IconHeart':
        return <IconHeart size={18} color='#FA5252' />;
      case 'IconBrain':
        return <IconBrain size={18} color='#FAB005' />;
      default:
        return null;
    }
  };

  const renderedIcon = getIconComponent(icon);

  const months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  useEffect(() => {
    if (dataWeekly && dataWeekly[0]) {
      if (!bpm) {
        const temp = [];
        const days = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

        dataWeekly[0].map((item, index) => {
          temp.push({ value: item, label: days[index] });
        });
        setWeeklyData(temp);
        setHighestWeekly(Math.max(...dataWeekly[0]));
      } else {
        const temp = [];
        const days = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
        dataWeekly[0].map((item, index) => {
          temp.push({
            value: item.max,
            barMarginBottom: item.min,
            label: days[index],
          });
        });
        setWeeklyData(temp);
        setHighestWeekly(Math.max(...dataWeekly[0]));
      }
    }
  }, [bpm, dataWeekly, currentYear]);
  useEffect(() => {
    if (dataMonthly && dataMonthly[0]) {
      if (!bpm) {
        const temp = [];
        console.log(dataMonthly[0]);
        dataMonthly[0].map((item, index) => {
          temp.push({
            value: item,
            label: index + 1,
          });
        });
        setMonthlyData(temp);
        setHighestMonthly(Math.max(...dataMonthly[0]));
      } else {
        const temp = [];
        dataMonthly[0].map((item, index) => {
          temp.push({
            value: item.max,
            barMarginBottom: item.min,
            label: index + 1,
          });
        });
        setMonthlyData(temp);
        setHighestMonthly(Math.max(...dataMonthly[0]));
      }
    }
  }, [bpm, dataMonthly]);

  return (
    <Card style={{}}>
      {canCloseChart && <Button onClick={onCloseChart}>CLOSE</Button>}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        <ActionIcon onClick={() => decreaseYear(select)}>
          <IconChevronLeft size={16} />
        </ActionIcon>
        <Text
          style={{
            color: '#A5ACB6',
            fontSize: 18,
            fontWeight: '500',
            fontFamily: 'Graphit',
          }}
        >
          {currentYear}
        </Text>
        <ActionIcon onClick={() => increaseYear(select)}>
          <IconChevronRight size={16} />
        </ActionIcon>
      </Box>

      <Box
        padding='md'
        style={{
          marginTop: '10px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          alignItems: 'start',
        }}
      >
        <ActionIcon onClick={() => decreaseDateHandle(select)}>
          <IconChevronLeft size={16} />
        </ActionIcon>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              color: '#A5ACB6',
              fontSize: 16,
              fontWeight: '500',
              fontFamily: 'Graphit',
            }}
          >
            {select === 'day'
              ? currentdate + 1
              : select === 'week'
              ? `Kalenderwoche ${currentWeek}`
              : select === 'month'
              ? `${months[currentMonth - 1]}`
              : null}
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {' '}
            {!bpm ? (
              <Text>Ingesamt {select === 'week' ? totalWeek : totalMonth}</Text>
            ) : (
              <Text>
                Durchschnittliche{' '}
                {select === 'week' ? totalWeekAverageBPM : totalMonthAverageBPM}
              </Text>
            )}
          </Box>
        </Box>
        <ActionIcon onClick={() => increaseDateHandle(select)}>
          <IconChevronRight size={16} />
        </ActionIcon>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '5px',
          marginLeft: '50px',
        }}
      >
        <Text>{title}</Text>
        {renderedIcon}
      </Box>

      <ResponsiveContainer width='100%' height={300}>
        <LineChart data={select === 'month' ? monthlyData : weeklyData}>
          <XAxis dataKey='label' />
          <YAxis />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip />
          <Line
            type='monotone'
            dataKey='value'
            stroke={frontColor}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>

      <Box
        padding='md'
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SegmentedControl
          size='md'
          data={[
            { label: 'Woche', value: 'week' },
            { label: 'Monat', value: 'month' },
          ]}
          value={select}
          onChange={(value) => setSelect(value)}
        />
      </Box>
    </Card>
  );
};

export default UserChartCard;
