import { ActionIcon, Avatar, Box, Text } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

export default function ChannelItem(props) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <ActionIcon
          sx={{ zIndex: 999, position: "relative", marginRight: 5 }}
          onClick={() => props.setOpenUser(props.user)}
        >
          <IconInfoCircle size={20} color="#98afc6" />
        </ActionIcon>
        <Box
          onClick={() => props.setSelected(props.user)}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: "4px 4px",
            width: "100%",
            minWidth: "200px",
            borderRadius: 6,
            background:
              props.selected && props.selected.channel === props.user.channel
                ? "#F4F7F9"
                : "none",
          }}
        >
          <Avatar
            src={props.user.image}
            size="sm"
            radius="sm"
            variant="outline"
            alt={props.user.name}
          />
          <Text
            ml={15}
            size="sm"
            sx={{
              position: "relative",
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                // whiteSpace: 'nowrap',
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text>{props.user.name}</Text>
                <Text
                  size="xs"
                  ml={10}
                  mr={5}
                  sx={{
                    color: "#637385",
                    position: "relative",
                    alignContent: "center",
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                  }}
                >
                  {props.user.id ? `ID: ${props.user.id}` : ""}
                </Text>
              </Box>

              <Text sx={{ fontSize: 10 }} color="gray">
                {props.user.program ? props.user.program : ""}
              </Text>
            </Box>
            {props.user.unreadMessages ? (
              <Box
                sx={{
                  background: "red",
                  borderRadius: 9999,
                  color: "white",
                  width: 12,
                  height: 12,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 8,
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
              >
                {props.user.unreadMessagesAmount === 0
                  ? 1
                  : props.user.unreadMessagesAmount}
              </Box>
            ) : (
              ""
            )}
          </Text>
        </Box>
      </Box>
    </>
  );
}
