import { useState } from 'react';
import axios from 'axios';
import { mutate } from 'swr';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Modal,
  TextInput,
  Loader,
  MultiSelect,
  Select,
  RangeSlider,
  Input,
} from '@mantine/core';

export const CreateBroadcastsModal = ({ opened, onClose, programms }) => {
  const [name, setName] = useState('');
  const [week, setWeek] = useState('');
  const [gender, setGender] = useState('');
  const [programm, setProgramm] = useState('');
  const [finished, setFinished] = useState('');
  const [rangeValue, setRangeValue] = useState([18, 100]);
  // const [rangeValueSteps, setRangeValueSteps] = useState([0, 30000]);
  const [plannedActivities, setPlannedActivities] = useState('');
  const [loadingBroadcast, setLoadingBroadcast] = useState(false);

  const [nameError, setNameError] = useState(false);

  const createBroadcast = async () => {
    if (!name.trim()) {
      setNameError(true);
      return;
    }

    setNameError(false);

    setLoadingBroadcast(true);
    const data = {};
    data.title = name;

    if (week) data.activeWeek = week;
    if (programm) data.program = programm;
    if (finished !== '') data.isFinished = finished;
    if (rangeValue[0]) data.ageRange = [rangeValue[0], rangeValue[1]];
    if (gender) data.gender = gender;
    if (plannedActivities !== '')
      data.isNoPlannedActivities = plannedActivities;
    // if (rangeValueSteps[1] !== 30000 || rangeValueSteps[0] !== 0)
    //   data.steps = [rangeValueSteps[0], rangeValueSteps[1]];
    await axios
      .post('https://api.rebirth.port4.de/v2/chat/channels/', data, {
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': localStorage.getItem('auth'),
        },
      })
      .then((response) => {
        console.log(response);
        setLoadingBroadcast(false);
        mutate('/v2/chat/channels/');
        setProgramm('');
        setWeek('');
        setPlannedActivities('');
        setFinished('');
        setGender('');
        setName('');
        setProgramm('');
        onClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingBroadcast(false);
      });
  };

  return (
    <Modal opened={opened} onClose={onClose} title='Broadcast erstellen'>
  <TextInput
  label='Name'
  value={name}
  onChange={(event) => {
    setName(event.target.value);
    if(nameError) setNameError(false);
  }}
  withAsterisk
  error={nameError && 'Dieses Feld ist erforderlich'}
/>

      <MultiSelect
        label='Woche'
        value={week}
        mt={10}
        onChange={setWeek}
        data={[
          { value: 1, label: 'Woche 1' },
          { value: 2, label: 'Woche 2' },
          { value: 3, label: 'Woche 3' },
          { value: 4, label: 'Woche 4' },
          { value: 5, label: 'Woche 5' },
          { value: 6, label: 'Woche 6' },
          { value: 7, label: 'Woche 7' },
          { value: 8, label: 'Woche 8' },
          { value: 9, label: 'Woche 9' },
          { value: 10, label: 'Woche 10' },
          { value: 11, label: 'Woche 11' },
          { value: 12, label: 'Woche 12' },
        ]}
      />
      <MultiSelect
        label='Programm'
        value={programm}
        mt={10}
        onChange={setProgramm}
        data={programms}
      />
      <Select
        label='Programm beendet'
        value={finished}
        mt={10}
        allowDeselect
        onChange={setFinished}
        data={[
          { value: false, label: 'Nein' },
          { value: true, label: 'Ja' },
        ]}
      />
      <Input.Wrapper label='Alter' mt={10}>
        <RangeSlider
          defaultValue={[20, 80]}
          mt={10}
          min={18}
          value={rangeValue}
          onChange={setRangeValue}
        />
      </Input.Wrapper>
      <Select
        label='Geschlecht'
        value={gender}
        mt={10}
        allowDeselect
        onChange={setGender}
        data={[
          { value: 'M', label: 'Männlich' },
          { value: 'F', label: 'Weiblich' },
        ]}
      />
      <Select
        label='Aktivitäten geplant'
        value={plannedActivities}
        mt={10}
        allowDeselect
        onChange={setPlannedActivities}
        data={[
          { value: true, label: 'Nein' },
          { value: false, label: 'Ja' },
        ]}
      />
      {/* <Input.Wrapper label='Schritte' mt={10}>
        <RangeSlider
          defaultValue={[2000, 30000]}
          mt={10}
          step={1000}
          max={30000}
          value={rangeValueSteps}
          onChange={setRangeValueSteps}
        />
      </Input.Wrapper> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
        }}
      >
        <Button
          onClick={() => createBroadcast()}
          sx={{
            border: '2px solid #A0B8CF',
            background: 'white',
            color: 'black',
            borderRadius: 32,
            '&:hover': {
              background: '#A0B8CF',
            },
          }}
        >
          {loadingBroadcast ? <Loader size='sm' /> : 'Erstellen'}
        </Button>
      </Box>
    </Modal>
  );
};

CreateBroadcastsModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  programms: PropTypes.array,
};
