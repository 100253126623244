import './App.css';
import { useEffect, useState, useRef } from 'react';
import PubNub from 'pubnub';
import axios from 'axios';
import ChannelItem from './components/ChannelItem';
import Chat from './components/Chat';
import logorebirth from './assets/logo-rebirth.f6d77c1f.svg';
import { ModalsProvider, modals } from '@mantine/modals';
import moment from 'moment-with-locales-es6';
import BroadcastList from './components/Broadcasts/BroadcastsList';
import ChallengeList from './components/Challenges/ChallengeList';
import GroupsList from './components/Groups/GroupsList';

import {
  AppShell,
  Navbar,
  Header,
  Text,
  Stack,
  MediaQuery,
  SimpleGrid,
  Progress,
  Burger,
  useMantineTheme,
  ActionIcon,
  Image,
  Tabs,
  Modal,
  TextInput,
  PasswordInput,
  Button,
  Badge,
  Box,
  Menu,
  UnstyledButton,
  Paper,
  ScrollArea,
} from '@mantine/core';
import {
  IconSelector,
  IconPlus,
  IconLogout,
  IconEyeOff,
  IconEyeCheck,
  IconSearch,
  IconX,
  IconUser,
  IconBroadcast,
  IconMathAvg,
  IconWalk,
  IconUsers,
} from '@tabler/icons-react';
import { UserButton } from './components/UserButton';
import logo from '../src/assets/not.png';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import useHook from './hooks/useHook';
import { Instance } from './hooks/axios';
import { showNotification, Notifications } from '@mantine/notifications';
import { mutate } from 'swr';
import useSound from 'use-sound';
import mySound from '../src/assets/sound.mp3'; // Your sound file path here
import UserChartCard from './components/UserChartCard';
import useFetchStats from './hooks/UseFetchStats';
import { CreateMembersGroupModal } from './components/Groups/Modals/CreateMembersGroupModal';
import { CreateChallengeModal } from './components/Challenges/Modals/CreateChallengeModal';
import { NutritionCard } from './components/NutritionCard';
import { CreateBroadcastsModal } from './components/Broadcasts/createBroadcastsModal';
import { FixedSizeList } from 'react-window';
import StatiticsAccordion from './components/Statistics/StatisticsAccordion';
import ChartToggleButtons from './components/Statistics/ChartToggleButtons';

function App() {
  const [pubnub, setPubnub] = useState();
  const [channels, setChannels] = useState();
  const [users, setUsers] = useState();
  const [usersSearched, setUsersSearched] = useState();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [auth, setAuth] = useState(false);
  const [selected, setSelected] = useState();
  const [programms, setProgramms] = useState();
  const [data, setData] = useState();
  const [calc, setCalc] = useState('');
  const [email, setEmail] = useState('');
  const [search, setSearch] = useState('');
  const [password, setPassword] = useState('');
  const [openUser, setOpenUser] = useState();
  const [activitiesWeek, setActivtiesWeek] = useState();
  const [allContent, setAllContent] = useState();
  const [listHeight, setListHeight] = useState(window.innerHeight);

  const [user, setUser] = useState();
  const [playSound] = useSound(mySound);
  const [openedDetails, { open: openDetail, close: closeDetail }] =
    useDisclosure(false);

  const [
    openedMemberGroups,
    { open: openMemberGroup, close: closeMemberGroup },
  ] = useDisclosure(false);

  const [openedChallenge, { open: openChallenge, close: closeChallenge }] =
    useDisclosure(false);

  const [openedBroadcast, { open: openBroadcast, close: closeBroadcast }] =
    useDisclosure(false);

  const [selectedBroadcastId, setSelectedBroadcastId] = useState();

  const days = ['MO', 'DI', 'MI', 'DO', 'FR', 'SA', 'SO'];

  const [activeTab, setActiveTab] = useState('steps', 'statistic', 'nutrition');

  const isMobile = useMediaQuery('(max-width: 768px)');

  const modalSize = isMobile ? '100%' : '50%';

  const [gridTemplateColumns, setGridTemplateColumns] = useState('auto');
  const [activeCharts, setActiveCharts] = useState({
    steps: true,
    activities: false,
    bpm: false,
    mental: false,
  });

  const resetActiveCharts = () => {
    setActiveCharts({
      steps: true,
      activities: false,
      bpm: false,
      mental: false,
    });
  };

  const renderUserChartCard = (
    totalWeek,
    totalMonth,
    frontColor,
    title,
    currentWeek,
    currentMonth,
    currentYear,
    dataMonthly,
    dataWeekly,
    bpm,
    increaseDateHandle,
    decreaseDateHandle,
    increaseYear,
    decreaseYear,
    icon,
    color
  ) => {
    return (
      <UserChartCard
        totalWeek={totalWeek && totalWeek.total}
        totalMonth={totalMonth && totalMonth.total}
        frontColor={frontColor}
        title={title}
        currentWeek={currentWeek}
        currentMonth={currentMonth}
        currentYear={currentYear}
        dataMonthly={dataMonthly}
        dataWeekly={dataWeekly}
        bpm={bpm}
        increaseDateHandle={increaseDateHandle}
        decreaseDateHandle={decreaseDateHandle}
        increaseYear={increaseYear}
        decreaseYear={decreaseYear}
        icon={icon}
        color={color}
      />
    );
  };

  const generateGridTemplate = () => {
    return '1fr';
  };

  const toggleChart = (chart) => {
    console.log('chart');
    if (!openUser) {
      return;
    }

    setActiveCharts((prev) => {
      console.log('prev', prev);
      if (prev[chart] && Object.values(prev).filter(Boolean).length === 1) {
        return prev;
      }

      return {
        ...prev,
        [chart]: !prev[chart],
      };
    });
  };

  useEffect(() => {
    const activeCount = Object.values(activeCharts).filter(Boolean).length;
    setGridTemplateColumns(generateGridTemplate(activeCount));
  }, [activeCharts]);

  const useStats = (name, user, charts) => {
    const {
      dataWeekly,
      dataMonthly,
      setCurrentWeekData,
      setCurrentMonthData,
      setCurrentYear,
      currentWeekData,
      currentMonthData,
      currentYear,
      resetCurrentDateData,
    } = useFetchStats(user, charts, name);

    const increaseDateHandle = (period) => {
      if (period === 'week') {
        setCurrentWeekData((week) => {
          week = parseInt(week);
          if (week >= 52) {
            // or 53, depending on the calendar
            setCurrentYear((year) => year + 1);
            return 1;
          } else {
            return week + 1;
          }
        });
      } else if (period === 'month') {
        setCurrentMonthData((month) => {
          month = parseInt(month);
          if (month >= 12) {
            setCurrentYear((year) => year + 1);
            return 1;
          } else {
            return month + 1;
          }
        });
      }
    };

    const decreaseDateHandle = (period) => {
      if (period === 'week') {
        setCurrentWeekData((week) => {
          week = parseInt(week);
          if (week <= 1) {
            setCurrentYear((year) => year - 1);
            return 52; // or 53, depending on the calendar
          } else {
            return week - 1;
          }
        });
      } else if (period === 'month') {
        setCurrentMonthData((month) => {
          month = parseInt(month);
          if (month <= 1) {
            setCurrentYear((year) => year - 1);
            return 12;
          } else {
            return month - 1;
          }
        });
      }
    };

    const increaseYear = () => {
      setCurrentYear((year) => year + 1);
    };

    const decreaseYear = () => {
      setCurrentYear((year) => year - 1);
    };

    return {
      dataWeekly,
      dataMonthly,
      currentWeekData,
      currentMonthData,
      currentYear,
      increaseDateHandle,
      decreaseDateHandle,
      increaseYear,
      decreaseYear,
      resetCurrentDateData,
    };
  };

  const stepsStats = useStats('steps', openUser, activeCharts);
  const activitiesStats = useStats('activities', openUser, activeCharts);
  const bpmStats = useStats('bpm', openUser, activeCharts);
  const mentalActivityStats = useStats('mental', openUser, activeCharts);

  const { data: usersFetch } = useHook('/v2/chat/clients/', 60000);
  const { data: broadcasts } = useHook('/v2/chat/channels/', 600000);

  const { data: userSearch } = useHook(
    `/v2/chat/clients?name=${search}`,
    60000
  );

  const { data: groups } = useHook(`/v2/chat/groups/`, 600000);

  const { data: challenges } = useHook(`/v2/challenges/`, 600000);

  const openModal = (item) =>
    modals.openConfirmModal({
      title: 'Broadcast löschen',
      centered: true,

      children: (
        <Text size='sm'>
          {`Bist du dir sicher, dass du ${item.title} unwiderruflich löschen
          möchtest?`}
        </Text>
      ),
      labels: { confirm: 'Löschen', cancel: 'Abbrechen' },
      confirmProps: { color: 'red' },

      onCancel: () => console.log('Abbrechen'),
      onConfirm: () => deleteBroadcast(item.id),
    });

  const openModalGroups = (item) =>
    modals.openConfirmModal({
      title: 'Gruppe löschen',
      centered: true,

      children: (
        <Text size='sm'>
          {`Bist du dir sicher, dass du ${item.title} unwiderruflich löschen
          möchtest?`}
        </Text>
      ),
      labels: { confirm: 'Löschen', cancel: 'Abbrechen' },
      confirmProps: { color: 'red' },

      onCancel: () => console.log('Abbrechen'),
      onConfirm: () => deleteGroup(item.id),
    });

  const openDeleteChallengeModal = (item) => {
    console.log(item);

    modals.openConfirmModal({
      title: 'Challenge löschen',
      centered: true,
      children: (
        <Text size='sm'>
          {`Bist du dir sicher, dass du ${item.challenge.title} unwiderruflich löschen
            möchtest?`}
        </Text>
      ),
      labels: { confirm: 'Löschen', cancel: 'Abbrechen' },
      onCancel: () => console.log('Abbrechen'),
      onConfirm: () => deleteChallenge(item.challengeId),
    });
  };

  useEffect(() => {
    const getProgramms = async () => {
      await axios
        .get('https://api.rebirth.port4.de/v1/reporting/programs/', {})
        .then((response) => {
          console.log(response);
          if (response.data) {
            const temp = [];
            response.data.data.map((item) => {
              temp.push({ value: item.id, label: item.title });
            });
            setProgramms(temp);
          }
        });
    };
    getProgramms();
  }, []);

  useEffect(() => {
    if (openUser) {
      openDetail();
      axios
        .get('https://api.rebirth.port4.de/v1/profile', {
          headers: {
            'X-AUTH-TOKEN': openUser.authCode,
          },
        })
        .then((response) => {
          setUser(response.data);
        });
    }
  }, [openUser, openDetail]);

  useEffect(() => {
    if (openUser) {
      openDetail();
      axios
        .get('https://api.rebirth.port4.de/v1/client/activitiesWeek', {
          headers: {
            'X-AUTH-TOKEN': openUser.authCode,
          },
        })
        .then((response) => {
          setActivtiesWeek(response.data);
        });

      axios
        .get('https://api.rebirth.port4.de/v1/client/allContent', {
          headers: {
            'X-AUTH-TOKEN': openUser.authCode,
          },
        })
        .then((response) => {
          console.log(response);
          setAllContent(response.data);
        });
    }
  }, [openUser, openDetail]);

  useEffect(() => {
    if (openUser) {
      console.log(openUser);
      openDetail();
      axios
        .get('https://api.rebirth.port4.de/v1/client/currentWeek', {
          headers: {
            'X-AUTH-TOKEN': openUser.authCode,
          },
        })
        .then((response) => {
          console.log(response);
          setData(response.data);
          let amount = 0;
          let number = 0;
          response.data.steps.map((item) => {
            if (item > 0) {
              amount += item;
              number++;
            }
          });
          setCalc(amount / number);
        });
    }
  }, [openUser, openDetail]);

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if (auth) setAuth(true);
    setPubnub(
      new PubNub({
        publishKey: localStorage.getItem('pubKey'),
        subscribeKey: localStorage.getItem('subKey'),
        uuid: 'Coach',
      })
    );
  }, [auth]);

  useEffect(() => {
    if (usersFetch) {
      const channelsTemp = [];
      const userstemp = [];
      usersFetch.map((item) => {
        if (item.channel) {
          channelsTemp.push(item.channel);
          userstemp.push(item);
        }
      });
      setChannels(channelsTemp);
      setUsers(userstemp);
    }
  }, [usersFetch]);

  useEffect(() => {
    if (userSearch) {
      setUsersSearched(userSearch);
    }
  }, [userSearch]);

  useEffect(() => {
    if (selected) {
      setOpened(false);
      updateNotification(selected.channel, false);
    }
  }, [selected]);

  useEffect(() => {
    if (users && !selected) {
      setSelected(users[0]);
    }
  }, [users]);

  const selectedChannelRef = useRef();

  useEffect(() => {
    // Store selected channel in ref
    selectedChannelRef.current = selected?.channel;
  }, [selected]);

  useEffect(() => {
    // Subscribe to channel group
    const subscribeToChannelGroup = async () => {
      if (pubnub) {
        try {
          console.log(`Subscribe to channel group`);

          // subscribe to channel group
          await pubnub.subscribe({ channelGroups: ['cg_coach'] });

          // add listener to handle new messages
          const listener = {
            message: function (m) {
              if (
                selectedChannelRef.current &&
                m.channel !== selectedChannelRef.current &&
                m.publisher !== 'Coach'
              ) {
                playSound();
                updateNotification(m.channel, true);
              }
            },
            file: function (event) {
              if (
                selectedChannelRef.current &&
                event.channel !== selectedChannelRef.current &&
                event.publisher !== 'Coach'
              ) {
                playSound();
                updateNotification(event.channel, true);
              }
            },
          };

          pubnub.addListener(listener);
        } catch (error) {
          console.error('Error subscribing to channel group:', error);
        }
      }
    };

    // call the async function
    subscribeToChannelGroup();

    // Cleanup function
    return () => {
      if (pubnub) {
        pubnub.unsubscribe({ channelGroups: ['cg_coach'] });
        pubnub.removeListener({
          message: function (m) {
            // listener cleanup
          },
        });
      }
    };
  }, [pubnub]);

  const updateNotification = async (channelName, value) => {
    await Instance.post(`/v2/chat/clients/messages`, {
      channel: channelName,
      unreadMessages: value,
    })
      .then(function (response) {
        if (response) {
          mutate('/v2/chat/clients/');
          mutate(`/v2/chat/clients?name=${search}`);
        }
      })
      .catch(function () {});
  };

  const openCreateMembersGroupModal = () => {
    openMemberGroup();
  };

  const openCreateChallengeModal = () => {
    openChallenge();
  };

  const openCreateBroadcastModal = () => {
    console.log('open');
    openBroadcast();
  };

  const logout = () => {
    setAuth(false);
    localStorage.removeItem('auth');
    localStorage.removeItem('pubKey');
    localStorage.removeItem('subKey');
  };

  const login = () => {
    axios
      .post('https://api.rebirth.port4.de/v2/user/login', {
        email,
        password,
      })
      .then((response) => {
        if (response) {
          localStorage.setItem('pubKey', response.data.pubKey);
          localStorage.setItem('subKey', response.data.subKey);
          localStorage.setItem('auth', response.data.auth);

          setAuth(true);
        }
      })
      .catch(() => {
        showNotification({
          autoClose: 3000,
          title: 'Fehler',
          message: 'Bitte Eingaben überprüfen',
          color: 'red',
        });
      });
  };

  const deleteGroup = (id) => {
    Instance.delete('https://api.rebirth.port4.de/v2/chat/groups/' + id)
      .then((response) => {
        if (response) {
          mutate('/v2/chat/groups/');
        }
      })
      .catch(() => {
        showNotification({
          autoClose: 3000,
          title: 'Fehler',
          message: 'Bitte Eingaben überprüfen',
          color: 'red',
        });
      });
  };

  const deleteBroadcast = (id) => {
    Instance.delete('https://api.rebirth.port4.de/v2/chat/channels/' + id)
      .then((response) => {
        if (response) {
          mutate('/v2/chat/channels/');
        }
      })
      .catch(() => {
        showNotification({
          autoClose: 3000,
          title: 'Fehler',
          message: 'Bitte Eingaben überprüfen',
          color: 'red',
        });
      });
  };

  const deleteChallenge = (id) => {
    Instance.delete('https://api.rebirth.port4.de/v2/challenge/' + id)
      .then((response) => {
        if (response) {
          mutate('/v2/challenges/');
        }
      })
      .catch(() => {
        showNotification({
          autoClose: 3000,
          title: 'Fehler',
          message: 'Bitte Eingaben überprüfen',
          color: 'red',
        });
      });
  };

  const closeInfo = () => {
    stepsStats.resetCurrentDateData();
    activitiesStats.resetCurrentDateData();
    bpmStats.resetCurrentDateData();
    mentalActivityStats.resetCurrentDateData();
    resetActiveCharts();
    closeDetail();
    setOpenUser();
  };

  const Row = ({ index, style }) => {
    const user = users[index];
    return (
      <div style={style}>
        <ChannelItem
          selected={selected}
          key={index + user.id}
          pubnub={pubnub}
          user={user}
          setOpenUser={setOpenUser}
          setSelected={setSelected}
        />
      </div>
    );
  };

  useEffect(() => {
    const updateSize = () => {
      setListHeight(window.innerHeight - 370);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <ModalsProvider>
      <Notifications />

      {auth ? (
        <Box>
          <Modal
            opened={openedDetails}
            onClose={() => closeInfo()}
            title='Teilnehmer'
            size={modalSize}
            centered
          >
            <Tabs variant='outline' value={activeTab} onChange={setActiveTab}>
              <Tabs.List>
                <Tabs.Tab value='information' disabled>
                  Informationen
                </Tabs.Tab>
                <Tabs.Tab value='steps' onClick={() => setActiveTab('steps')}>
                  Schritte
                </Tabs.Tab>
                <Tabs.Tab
                  value='statistic'
                  onClick={() => setActiveTab('statistic')}
                >
                  Statistik
                </Tabs.Tab>
                <Tabs.Tab
                  value='activity'
                  onClick={() => setActiveTab('activity')}
                >
                  Aktivität
                </Tabs.Tab>
                <Tabs.Tab
                  value='content'
                  onClick={() => setActiveTab('content')}
                >
                  Artikel
                </Tabs.Tab>
                <Tabs.Tab
                  value='nutrition'
                  onClick={() => setActiveTab('nutrition')}
                >
                  Nutrition
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value='gallery' pt='xs'>
                Gallery tab content
              </Tabs.Panel>

              <Tabs.Panel value='messages' pt='xs'>
                Messages tab content
              </Tabs.Panel>

              <Tabs.Panel value='settings' pt='xs'>
                Settings tab content
              </Tabs.Panel>
            </Tabs>
            {activeTab === 'content' && (
              <Box sx={{ padding: 20 }}>
                <Text size='sm' mb={10} mt={10}>
                  Gelesen
                </Text>
                <ScrollArea offsetScrollbars h={300} type='always'>
                  {allContent &&
                    allContent.read &&
                    allContent.read.map((item) => {
                      return (
                        <Paper
                          withBorder
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 10,
                            background: item.favourite ? '#E9F3F1' : '',
                            marginBottom: 10,
                          }}
                        >
                          <Box w={200}>
                            <Text size='xs'>{item.title}</Text>
                          </Box>
                          <Badge w={120} color='gray'>
                            {item.type}
                          </Badge>
                          <Text size='xs'>{item.rating}</Text>
                        </Paper>
                      );
                    })}
                </ScrollArea>
                <Text size='sm' mb={10} mt={40}>
                  Ungelesen
                </Text>
                <ScrollArea offsetScrollbars h={300} type='always'>
                  {allContent &&
                    allContent.unread &&
                    allContent.unread.map((item) => {
                      return (
                        <Paper
                          withBorder
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 10,
                            background: item.favourite ? '#E9F3F1' : '',
                            marginBottom: 10,
                          }}
                        >
                          <Box w={200}>
                            <Text size='xs'>{item.title}</Text>
                          </Box>
                          <Badge w={120} color='gray'>
                            {item.type}
                          </Badge>
                          <Text size='xs'>{item.rating}</Text>
                        </Paper>
                      );
                    })}
                </ScrollArea>
              </Box>
            )}
            {activeTab === 'activity' && (
              <Box sx={{ padding: 20 }}>
                <Text size='sm' mb={10} mt={10}>
                  Wochenziele
                </Text>
                <SimpleGrid cols={2}>
                  <Stack>
                    <Text size='xs' mb={-10} mt={10}>
                      {`Fitness: ${activitiesWeek.plannedActivityMinutes.FITNESS} / ${activitiesWeek.plannedActivityMinutes.FITNESS_GOAL} min`}
                    </Text>
                    <Progress
                      radius='xl'
                      size={12}
                      sections={[
                        {
                          value:
                            (100 *
                              activitiesWeek.plannedActivityMinutes.FITNESS) /
                            activitiesWeek.plannedActivityMinutes.FITNESS_GOAL,
                          color: '#7FDEC6',
                        },
                      ]}
                    />
                  </Stack>
                  <Stack>
                    <Text size='xs' mb={-10} mt={10}>
                      {`Mental: ${activitiesWeek.plannedActivityMinutes.MENTAL} / ${activitiesWeek.plannedActivityMinutes.MENTAL_GOAL} min`}
                    </Text>
                    <Progress
                      radius='xl'
                      size={12}
                      sections={[
                        {
                          value:
                            (100 *
                              activitiesWeek.plannedActivityMinutes.MENTAL) /
                            activitiesWeek.plannedActivityMinutes.MENTAL_GOAL,
                          color: '#FFD077',
                        },
                      ]}
                    />
                  </Stack>
                </SimpleGrid>
              </Box>
            )}
            {activeTab === 'steps' && (
              <Box sx={{ padding: 20 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text size='sm' mb={10}>
                    {data &&
                      data.steps[
                        moment(new Date()).isoWeekday() - 1
                      ].toLocaleString('de')}{' '}
                    von {data && data.stepsGoalDay.toLocaleString('de')}
                  </Text>
                  <Text
                    size='sm'
                    mb={10}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <IconMathAvg strokeWidth={1} size={18} />
                    <Text sx={{ position: 'relative', top: 1, marginLeft: 5 }}>
                      {' '}
                      {calc ? Math.ceil(calc) : 0}
                    </Text>
                  </Text>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {data &&
                    data.steps &&
                    data.steps.map((item, index) => {
                      return (
                        <Text
                          size='xs'
                          color='gray'
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color:
                              moment(new Date()).isoWeekday() - 1 === index
                                ? 'black'
                                : 'gray',
                          }}
                        >
                          <Box
                            sx={{
                              height: 100,
                              backgroundColor: '#E9F3F1',
                              width: 10,
                              overflow: 'hidden',
                              border:
                                item >= data.stepsGoalDay
                                  ? '1px solid #00D6A2'
                                  : '1px solid #E9F3F1',
                              borderRadius: 999,
                              marginBottom: 5,
                              alignItems: 'end',
                              display: 'flex',
                            }}
                          >
                            <Box
                              sx={{
                                height: (100 * item) / data.stepsGoalDay,
                                marginTop: 'auto',
                                borderRadius: 999,
                                backgroundColor: '#7FDEC6',
                                width: 10,
                              }}
                            ></Box>
                          </Box>
                          {days[index]}
                        </Text>
                      );
                    })}
                </Box>
              </Box>
            )}
            {activeTab === 'statistic' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {isMobile ? (
                  <StatiticsAccordion
                    stepsStats={stepsStats}
                    activitiesStats={activitiesStats}
                    bpmStats={bpmStats}
                    mentalActivityStats={mentalActivityStats}
                    user={user}
                    renderUserChartCard={renderUserChartCard}
                    openChart={toggleChart}
                  />
                ) : (
                  <ChartToggleButtons
                    activeCharts={activeCharts}
                    toggleChart={toggleChart}
                    user={user}
                  />
                )}
                {!isMobile && (
                  <Box
                    key={gridTemplateColumns}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns,
                      gap: 20,
                    }}
                  >
                    {activeCharts.steps &&
                      renderUserChartCard(
                        stepsStats.dataWeekly,
                        stepsStats.dataMonthly,
                        '#12B886',
                        'Schritte',
                        stepsStats.currentWeekData,
                        stepsStats.currentMonthData,
                        stepsStats.currentYear,
                        stepsStats.dataMonthly,
                        stepsStats.dataWeekly,
                        false,
                        stepsStats.increaseDateHandle,
                        stepsStats.decreaseDateHandle,
                        stepsStats.increaseYear,
                        stepsStats.decreaseYear,
                        'IconWalk',
                        '#8884d8'
                      )}
                    {activeCharts.activities &&
                      renderUserChartCard(
                        activitiesStats.dataWeekly,
                        activitiesStats.dataMonthly,
                        '#228BE6',
                        'Aktivitat',
                        activitiesStats.currentWeekData,
                        activitiesStats.currentMonthData,
                        activitiesStats.currentYear,
                        activitiesStats.dataMonthly,
                        activitiesStats.dataWeekly,
                        false,
                        activitiesStats.increaseDateHandle,
                        activitiesStats.decreaseDateHandle,
                        activitiesStats.increaseYear,
                        activitiesStats.decreaseYear,
                        'IconRun',
                        '#8884d8'
                      )}

                    {activeCharts.bpm &&
                      user.clientData.connectedDevice &&
                      user.clientData.connectedDevice !== 'FitBit' &&
                      user.clientData.connectedDevice !== 'Oura' &&
                      user.clientData.connectedDevice !== 'Polar' &&
                      renderUserChartCard(
                        bpmStats.dataWeekly,
                        bpmStats.dataMonthly,
                        '#FA5252',
                        'BPM',
                        bpmStats.currentWeekData,
                        bpmStats.currentMonthData,
                        bpmStats.currentYear,
                        bpmStats.dataMonthly,
                        bpmStats.dataWeekly,
                        true,
                        bpmStats.increaseDateHandle,
                        bpmStats.decreaseDateHandle,
                        stepsStats.increaseYear,
                        stepsStats.decreaseYear,
                        'IconHeart',
                        '#8884d8'
                      )}
                    {activeCharts.mental &&
                      renderUserChartCard(
                        mentalActivityStats.dataWeekly,
                        mentalActivityStats.dataMonthly,
                        '#FAB005',
                        'Mental Aktivität',
                        mentalActivityStats.currentWeekData,
                        mentalActivityStats.currentMonthData,
                        mentalActivityStats.currentYear,
                        mentalActivityStats.dataMonthly,
                        mentalActivityStats.dataWeekly,
                        false,
                        mentalActivityStats.increaseDateHandle,
                        mentalActivityStats.decreaseDateHandle,
                        stepsStats.increaseYear,
                        stepsStats.decreaseYear,
                        'IconBrain',
                        '#8884d8'
                      )}
                  </Box>
                )}
              </Box>
            )}
            {activeTab === 'nutrition' && <NutritionCard openUser={openUser} />}
          </Modal>

          <CreateMembersGroupModal
            opened={openedMemberGroups}
            onClose={() => closeMemberGroup()}
          />

          <CreateChallengeModal
            programms={programms}
            opened={openedChallenge}
            onClose={() => closeChallenge()}
          />

          <CreateBroadcastsModal
            programms={programms}
            opened={openedBroadcast}
            onClose={() => closeBroadcast()}
          />

          <AppShell
            styles={{
              main: {
                background:
                  theme.colorScheme === 'dark' ? theme.colors.dark[8] : 'white',
              },
            }}
            navbarOffsetBreakpoint='sm'
            asideOffsetBreakpoint='sm'
            navbar={
              <Navbar
                p='md'
                hiddenBreakpoint='sm'
                withBorder={false}
                sx={{
                  zIndex: 200,
                }}
                hidden={!opened}
                width={{ sm: 200, lg: 300 }}
              >
                <Menu shadow='md' width={200}>
                  <Menu.Target>
                    <UnstyledButton>
                      {' '}
                      <UserButton
                        image={logo}
                        name='rebirth:active'
                        email='Coach'
                        icon={<IconSelector size='0.9rem' stroke={1.5} />}
                      />
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>rebirth:active</Menu.Label>
                    <Menu.Item
                      onClick={() => logout()}
                      icon={<IconLogout size={14} />}
                    >
                      Abmelden
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>

                <Navbar.Section grow component={ScrollArea}>
                  <Tabs color='#d2e8e2' variant='pills' defaultValue='users'>
                    <Tabs.List>
                      <Tabs.Tab value='users' icon={<IconUser size='0.8rem' />}>
                        Teilnehmer
                      </Tabs.Tab>

                      <Tabs.Tab
                        value='broadcasts'
                        icon={<IconBroadcast size='0.8rem' />}
                      >
                        Broadcasts
                      </Tabs.Tab>
                      <Tabs.Tab
                        value='groups'
                        icon={<IconUsers size='0.8rem' />}
                      >
                        Gruppen
                      </Tabs.Tab>
                      <Tabs.Tab
                        value='challenges'
                        icon={<IconWalk size='0.8rem' />}
                      >
                        Challenges
                      </Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value='users' pt='xs'>
                      <TextInput
                        sx={{ border: '0!important' }}
                        className='search'
                        icon={<IconSearch size={14} />}
                        value={search}
                        rightSection={
                          search && (
                            <Box
                              sx={{
                                cursor: 'pointer',
                                position: 'relative',
                                top: 1,
                              }}
                              onClick={() => setSearch('')}
                            >
                              <IconX size={12} />
                            </Box>
                          )
                        }
                        onChange={(event) => setSearch(event.target.value)}
                      />
                      <Text size='sm' ml={10} mt={20} mb={10}>
                        Teilnehmer
                      </Text>

                      {users && pubnub && !search ? (
                        <FixedSizeList
                          height={listHeight}
                          itemSize={50}
                          itemCount={users.length}
                        >
                          {Row}
                        </FixedSizeList>
                      ) : (
                        <ScrollArea
                          h={usersSearched && pubnub && search ? 250 : 0}
                          type='always'
                        >
                          {usersSearched &&
                            pubnub &&
                            search &&
                            usersSearched.map((user, index) => (
                              <ChannelItem
                                selected={selected}
                                key={index}
                                pubnub={pubnub}
                                user={user}
                                setSelected={setSelected}
                                setOpenUser={setOpenUser}
                              />
                            ))}
                        </ScrollArea>
                      )}
                    </Tabs.Panel>

                    <Tabs.Panel value='broadcasts' pt='xs'>
                      <Text
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        size='sm'
                        ml={10}
                        mt={30}
                        mb={10}
                      >
                        Broadcasts{' '}
                        <ActionIcon onClick={() => openCreateBroadcastModal()}>
                          <IconPlus size={16} />
                        </ActionIcon>
                      </Text>
                      <BroadcastList
                        broadcasts={broadcasts}
                        selected={selected}
                        setSelected={setSelected}
                        openModal={openModal}
                        onBroadcastSelect={setSelectedBroadcastId}
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value='groups' pt='xs'>
                      <Text
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        size='sm'
                        ml={10}
                        mt={30}
                        mb={10}
                      >
                        Gruppen{' '}
                        <ActionIcon
                          onClick={() => openCreateMembersGroupModal()}
                        >
                          <IconPlus size={16} />
                        </ActionIcon>
                      </Text>
                      <GroupsList
                        groups={groups}
                        selected={selected}
                        setSelected={setSelected}
                        openModalGroups={openModalGroups}
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value='challenges' pt='xs'>
                      <Text
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        size='sm'
                        ml={10}
                        mt={30}
                        mb={10}
                      >
                        Challenges{' '}
                        <ActionIcon onClick={() => openCreateChallengeModal()}>
                          <IconPlus size={16} />
                        </ActionIcon>
                      </Text>
                      <ChallengeList
                        challenges={challenges}
                        selected={selected}
                        openDeleteChallengeModal={openDeleteChallengeModal}
                      />
                    </Tabs.Panel>
                  </Tabs>
                </Navbar.Section>
              </Navbar>
            }
            // aside={
            //   <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
            //     <Aside p='md' hiddenBreakpoint='sm' width={{ sm: 200, lg: 300 }}>
            //       <Text>Application sidebar</Text>
            //     </Aside>
            //   </MediaQuery>
            // }
            header={
              <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
                <Header height={{ base: 50, md: 0 }} p='md'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Burger
                      opened={opened}
                      onClick={() => setOpened((o) => !o)}
                      size='sm'
                      color={theme.colors.gray[6]}
                      mr='xl'
                    />
                  </div>
                </Header>
              </MediaQuery>
            }
          >
            <Chat
              user={selected}
              pubnub={pubnub}
              broadcastId={selectedBroadcastId}
            />
          </AppShell>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            background:
              'linear-gradient(to right top, #f7e3d5, #f8deda, #f3dae0, #e8d9e6, #dbd8e8, #d4dae9, #cedbe8, #c9dce5, #c9dfe5, #cbe3e5, #cee5e3, #d2e8e2);',
          }}
        >
          <Paper
            className='glass'
            sx={{
              padding: 20,
              width: '80%',
              '@media (min-width: 768px)': {
                width: '25%',
                maxWidth: 360,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 30,
                marginTop: 20,
              }}
            >
              <Image sx={{ width: '130px!important' }} src={logorebirth} />
            </Box>
            <TextInput
              label='E-Mail'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              withAsterisk
            />
            <PasswordInput
              label='Passwort'
              defaultValue='secret'
              value={password}
              withAsterisk
              mt={10}
              onChange={(event) => setPassword(event.target.value)}
              visibilityToggleIcon={({ reveal, size }) =>
                reveal ? (
                  <IconEyeOff size={size} />
                ) : (
                  <IconEyeCheck size={size} />
                )
              }
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 40,
              }}
            >
              <Button
                onClick={() => login()}
                sx={{
                  border: '2px solid #A0B8CF',
                  background: 'white',
                  color: 'black',
                  borderRadius: 32,
                  '&:hover': {
                    background: '#A0B8CF',
                  },
                }}
              >
                Anmelden
              </Button>
            </Box>
          </Paper>
        </Box>
      )}
    </ModalsProvider>
  );
}

export default App;
