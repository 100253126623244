import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment-with-locales-es6';

const useFetchStats = (openUser, activeCharts, dataType) => {
  const [currentWeekData, setCurrentWeekData] = useState(
    moment(new Date()).isoWeek()
  );
  const [currentMonthData, setCurrentMonthData] = useState(
    moment(new Date()).locale('de').format('M')
  );

  const [currentYear, setCurrentYear] = useState(moment(new Date()).year());

  const [dataWeekly, setDataWeekly] = useState(null);
  const [dataMonthly, setDataMonthly] = useState(null);

  const fetchWeeklyData = useCallback(() => {
    if (openUser && activeCharts[dataType]) {
      axios
        .get(
          `https://api.rebirth.port4.de/v1/client/statistics/${dataType}/weekly?cw=${currentWeekData}&year=${currentYear}`,
          {
            headers: {
              'X-AUTH-TOKEN': openUser.authCode,
            },
          }
        )
        .then((response) => {
          setDataWeekly(response.data);
          console.log('hookResponse', response.data);
        })
        .catch((error) => {
          console.error('There was an error!', error);
          console.log('hookError', error);
        });
    }
  }, [openUser, activeCharts, dataType, currentWeekData, currentYear]);

  const fetchMonthlyData = useCallback(() => {
    if (openUser && activeCharts[dataType]) {
      axios
        .get(
          `https://api.rebirth.port4.de/v1/client/statistics/${dataType}/monthly?month=${currentMonthData}&year=${currentYear}`,
          {
            headers: {
              'X-AUTH-TOKEN': openUser.authCode,
            },
          }
        )
        .then((response) => {
          setDataMonthly(response.data);
        })
        .catch((error) => {
          console.error('There was an error!', error);
        });
    }
  }, [openUser, activeCharts, dataType, currentMonthData, currentYear]);

  useEffect(() => {
    fetchWeeklyData();
  }, [fetchWeeklyData]);

  useEffect(() => {
    fetchMonthlyData();
  }, [fetchMonthlyData]);
  const resetCurrentDateData = useCallback(() => {
    setCurrentWeekData(moment(new Date()).isoWeek());
    setCurrentMonthData(moment(new Date()).locale('de').format('M'));
    setCurrentYear(new Date().getFullYear());
  }, []);

  return {
    dataWeekly,
    dataMonthly,
    setCurrentWeekData,
    setCurrentMonthData,
    setCurrentYear,
    currentWeekData,
    currentMonthData,
    resetCurrentDateData,
    currentYear,
  };
};

export default useFetchStats;
